import logo from './logo.svg';
import React, { useRef } from 'react';
import './App.css';
import { Canvas, useFrame } from '@react-three/fiber';
import { OrbitControls } from '@react-three/drei';
import { TextureLoader } from 'three';
import { useLoader } from '@react-three/fiber';

function Globe() {
  const texture = useLoader(TextureLoader, '/globe_texture.jpg');
  // const texture = useLoader(TextureLoader, process.env.PUBLIC_URL + '/globe_texture.jpg');
  const meshRef = useRef();

  useFrame(() => {
    if (meshRef.current) {
      meshRef.current.rotation.y += 0.005;  // Ajuste a velocidade de rotação conforme necessário
    }
  });

  return (
    <mesh ref={meshRef}>
      <sphereGeometry args={[2.5, 32, 32]} />  {/* Aumentei o tamanho do globo */}
      <meshStandardMaterial map={texture} />
    </mesh>
  );
}

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h1>VirtuAllWorld</h1>
        <nav>
          <a href="#home">Home</a>
          <a href="#about">About</a>
          <a href="#services">Services</a>
          <a href="#contact">Contact</a>
        </nav>
      </header>
      <main>
        <div className="hero">
          <Canvas>
            <ambientLight intensity={5} />
            <directionalLight position={[5, 5, 5]} intensity={3} />
            <pointLight position={[10, 10, 10]} intensity={3} />
            <OrbitControls enableZoom={false} />
            <Globe />
          </Canvas>
          <div className="hero-text">
            <h2>Explore o Mundo Virtual</h2>
          </div>
        </div>
        <section id="about">
          <h2>About Us</h2>
          <p>Informações sobre a empresa e sua missão.</p>
        </section>
        <section id="services">
          <h2>Our Services</h2>
          <p>Breve descrição dos principais serviços oferecidos.</p>
        </section>
        <section id="contact">
          <h2>Contact Us</h2>
          <form>
            <label>
              Name:
              <input type="text" name="name" />
            </label>
            <label>
              Email:
              <input type="email" name="email" />
            </label>
            <label>
              Message:
              <textarea name="message" />
            </label>
            <button type="submit">Send</button>
          </form>
        </section>
      </main>
      <footer>
        <p>&copy; 2024 VirtuAllWorld. All rights reserved.</p>
      </footer>
    </div>
  );
}

export default App;
